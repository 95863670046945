.flex-fill {
  flex: 1 1 0 !important;
}

@media (max-width: 990px) {
  .flex-md-down-fill {
    flex: 1 1 0 !important;
  }
}

.codeage-gold {
  color: $codeage-gold;
}
